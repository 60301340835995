import * as React from "react"
import Layout from "../components/layout2023"
import Seo from "../components/seo2023"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import TestimonialsV2 from "../componentsv2/testimonialsv2/testimonialsv2"
const Testimonials = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo title="Testimonials" />
    <TestimonialsV2 />

    <FooterV2 version="v2" />
  </Layout>
)

export default Testimonials
